<template>
<div style="">
                <!-- <div style="margin-top: 90px; margin-bottom: -50px; display: flex; flex-grow: 1; justify-content: center">
                    <div id="waldo-tag-12625"></div>
                </div> -->
<main class="main" role="main">
    <div class="main-container" style="display:flex;flex-direction:row">
    
        <div class="sec-left" style="max-width:40%;">
            <div class="col_50">
                <div class="col_50" >
                    <h1 style="font-weight: normal; color: rgb(0, 0, 0); font-size: 22px;">About clock.zone</h1>
                    <p>As I mentioned on home page, my main goal was to build a website where you can find&nbsp;<strong style="font-weight: bold;">real exact time</strong>.</p>
                    <p><strong style="font-weight: bold;">Most of the applications, widgets or websites are showing time from your device clock.</strong> But can you trust to clock built in your computer or mobile phone? Sometimes it may be late or ahead (few seconds or even minutes). Or maybe time zone is not set properly, or you forgot to set the DST time, etc.</p>
                    <p>All analog and digital clocks, widgets on clock.zone shows time from our dedicated server synchronised with atomic clock.</p>
                    <p>On clock.zone you can find exact times for time zones world wide too. You can set this clock to run in background or place it on your website as widget.</p>
                    <!-- <div style="display: flex; flex-grow: 1; justify-content: center">
                        <div id="waldo-tag-12334"></div>
                    </div> -->
                    <h3 style="font-weight: bold; color: rgb(0, 0, 0);">Summary</h3>
                    <p><img src="https://clock.zone/images/about.gif" width="275" height="129" style="border: 0px;"><br>(A): Offset of clock in your computer, (ahead or late, in seconds). If this number is very big (example 3605.4 s), check settings of your default time zone or DST.<br>(B): Precision of synchronisation (time since request is sent and received). If this number is too big (due the slow internet connection in request moment), shown time may have this delay.<br>(C): counter of succesiful synchronisation (just informative)<br>(D): Latest synchronisation (just informative)<br>(E): counter of fail synchronisation (no problem, site will try later again and again..)</p>
                    <h3 style="font-weight: bold; color: rgb(0, 0, 0);">Technical details</h3>
                    <p>In moment when you request the content of the page with clock, exact time is sent together with html code (milliseconds since Epoch time).</p>
                    <p>Offset is calculated (between time in your device and our server time). From that moment, your clock is used to calculate the past time. BUT every 10-60 seconds AJAX is used in background to check is your time still accurate. What this means? For example, if your device clock is late 5 minutes per day (that&apos;s very much), in 1 minute delay will be only 0.2 seconds. And every minute AJAX will try to synchronise in background the time with clock.zone server. What happens if internet connection is lost? In that case, AJAX will continue to try again, when internet connection is back, clock will be accurate again.</p>
                    <p>There are few technical problems: One is your internet speed. There is a delay since our server send the exact time and your computer get the data. To predict the precision, there is a small piece of code, that measure the time from request sending and receieving (B) client-&gt;server-&gt;client. The real delay is only traveling time from server-&gt;client, but in script running on your computer (JS), I can only measure start of the request where client-&gt;server time is included too.</p>
                    <p>Another technical problem is the temporary load of your device in moment, when it measure the time and process the information.</p>
                    <p>Oh, yes, and there is always existing problem, compatibility with internet browsers. I tried to use solutions, where main digital and analog clock scripts supports Firefox&nbsp;3.0+, Safari&nbsp;3.0+, Chrome&nbsp;5.0+, Opera&nbsp;9.5+ and Internet Explorer&nbsp;6.0+. Some clocks in &quot;<a href="https://clock.zone/more/" style="color: rgb(51, 102, 153);">more clocks</a>&quot; category working only with new modern browsers, but those pages are only for fun (but of course, they show exact times too).</p>
                </div>

                <div class="clearfix" style="zoom: 1; color: rgb(51, 51, 51); font-family: arial, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"><br></div>
                <hr style="display: block; height: 1px; border-width: 1px 0px 0px; border-right-style: initial; border-bottom-style: initial; border-left-style: initial; border-right-color: initial; border-bottom-color: initial; border-left-color: initial; border-image: initial; border-top-style: solid; border-top-color: rgb(204, 204, 204); margin: 1em 0px; padding: 0px; color: rgb(51, 51, 51); font-family: arial, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
                <p style="color: rgb(51, 51, 51); font-family: arial, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">Your feedback is welcome.</p>
            </div>
        </div>
        <div class="sec-right">
            <div class="col_50">
                <h2 style="font-weight: normal; color: rgb(0, 0, 0);">Demonstration of clock.zone precision</h2>
                <!-- <div style="text-align: center; color: black; display: flex; flex-grow: 1; justify-content: center">
                    <div id="waldo-tag-12623"></div>
                </div> -->
                <p><iframe width="560" height="315" src="https://www.youtube.com/embed/9V_YQ_LjStI?rel=0" frameborder="0" allowfullscreen=""></iframe></p>
                <!-- <div style="margin-top: 8px; text-align: center; color: black; display: flex; flex-grow: 1; justify-content: center">
                    <div id="waldo-tag-12624"></div>
                </div> -->
            </div>
        </div>
        
    </div>
        <!-- <div style="text-align: center; color: black; display: flex; flex-grow: 1; justify-content: center">
            <div id="waldo-tag-12626"></div>
        </div> -->
     <footers></footers>
</main>
</div>
</template>
<script>
import footers from '@/views/Footer.vue'
export default {
    components: {footers},
    data() {
        return {
            
        }
    },
    watch: {
        '$router.params': {
            handler(newValue, oldValue) {
                this.init()
                // setTimeout(d=>{
                // waldo.refreshTag("waldo-tag-12623")
                //  waldo.refreshTag("waldo-tag-12334")
                //  waldo.refreshTag("waldo-tag-12624")
                //  waldo.refreshTag("waldo-tag-12625")
                //  waldo.refreshTag("waldo-tag-12626")
                //         console.log(4)
                //  },1000)
            }
        }
    },
    created() {
       
        this.init()
        // setTimeout(d=>{
        //          waldo.refreshTag("waldo-tag-12623")
        //          waldo.refreshTag("waldo-tag-12334")
        //          waldo.refreshTag("waldo-tag-12624")
        //          waldo.refreshTag("waldo-tag-12625")
        //          waldo.refreshTag("waldo-tag-12626")
        // },1000)
    },
    methods: {
      
        init() {
           document.title =  "About clock.zone";
        }
    }
}
</script>
<style scoped>
@media screen and (max-width: 960px) {
    .main-container{
        flex-direction:column !important;
    }
    .sec-left{
        min-width:98%;
        max-width:unset;
    }
    .sec-right h2{
    color: rgb(0, 0, 0) !important;
    text-align: center !important;
    margin: 0 !important;
    padding: 0 !important;
    margin-left: 47px !important;
    font-weight: bold !important;
    }
}
</style>

